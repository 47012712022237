import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"

function FormPage () {

return (
<>
<SEO title="Clarify Check-in"/> 
<div className="pt-6">
    <img className="mx-auto" width="300px" alt="" src="https://res.cloudinary.com/mysnapshot/image/upload/v1642057964/Course%20Logos/mys-logo-big_jreq71.png"/>
</div>
<div className="-mt-8" id="ff-compose"></div>
<Helmet>
    <style>{`
        #Publish1FAIpQLSc1iM090Ewh7W0Sofj8J6yU8jBsE3-AZLtz-q4c71RJv2QmaQ {
            padding: 30px 30px; 
            
        }

        .ff-compose {
            min-height: calc(100vh - 30px);
        }

        #___gatsby {
            background-color: #FFFFFF !important;
        }

        .ff-partial {
            margin-top: 2.2em; 
        }
        
        #ff-sec-root {
            padding-top: 2em !important;
            min-height: 100%;
            
        }

        @media only screen and (min-width: 600px) {
            #ff-sec-root {
                padding-left: 10px !important; 
                padding-right: 10px !important; 
            }
          }

        .ff-section {
            padding-top: 4em;
            min-height: 100%;
        }

        .ff-section-header {
            font-size: 20px !important;
            margin-top: 0.5em !important; 
            color: #141313 !important;
            font-weight: bold !important; 
        }

        @media only screen and (max-width: 768px) {
            .ff-section {
            min-height: 100%; 
            }
        }

        .ff-form table { 
            line-height: 1.15rem; 
            margin-top: 1em;
        }

        .ff-form .ff-item label {
            font-size: 16px !important;
            font-weight: bold !important;
            padding-bottom: 0px !important; 
            margin-bottom: 8px !important; 
        }

        .ff-form .ff-item {
            padding-top: 25px !important;
        }

        .ff-success {
            padding: 80px 30px !important; 
            font-size: 18px !important; 
            height: 100vh; 
            min-height: 100% !important;
            color: #141313;
        }

        #Help1421743561 {
            color: red !important; 
            font-size: 14px; 
            line-height: 1.4em; 
            margin-top: 0.5em; 
            margin-bottom: 0.5em; 
        }

        img {
            margin-bottom: 0em !important;
        }

        .ff-next, .ff-submit {
            background-color: #4B89DC !important;
            color: white !important;
            vertical-align: middle;
            align-items: center;
        } 

				.ff-next:hover, .ff-submit:hover {
           box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
           filter: brightness(0.9) !important;   
           transition-timing-function: ease-in;
           transition-duration: 0.15s;
        }

        .ff-back {
        }

        .ff-form table tr td { 
            border: none !important; 
        }

        .ff-button-bar {
            padding-bottom: 4em !important; 
            padding-top: 2.5em !important; 
        }

        textarea {
            margin-bottom: 0em; 
        }

        .form-control {
            outline-color: #d1d2d9 !important; 
            outline-style: solid !important; 
            outline-width: 0.05em !important;
        }

        .ff-description {
            font-size: 16px;
        }

        #ff-desc-2016523132 {
            margin-top: 2em !important; 
        }

        .ff-scale input {
            width: 18px;
            height: 18px;
        }

        @media only screen and (max-width: 600px) {
            .ff-scale input {
              width: 12px;
              height: 12px;
            }
          }

        table {
            margin-bottom: 0em !important;
        }

        // .ff-paragraph_text label {
        //     color: #74778c !important;
        // }

        small {
            color: #74778c !important;
            font-size: 12px; 
        }

        .ff-title {
            padding-bottom: 0.75em !important;
        }

        td {
            font-size: 13px;
            font-weight: bold !important;
            margin-top: 2em !important; 
        }

        @media only screen and (max-width: 600px) {
            td {
              font-size: 11px; 
            }
          }
        `}
    </style>
        <script async defer src="https://formfacade.com/include/105416567367288830990/form/1FAIpQLSc1iM090Ewh7W0Sofj8J6yU8jBsE3-AZLtz-q4c71RJv2QmaQ/classic.js?div=ff-compose">
        </script></Helmet>


</>
)
} 

export default FormPage
